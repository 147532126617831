/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Tooltips
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-tooltip) {
  background-color: var(--media-tooltip-bg-color, black);
  border: var(--media-tooltip-border, 1px solid rgb(255 255 255 / 0.1));
  border-radius: var(--media-tooltip-border-radius, 2px);
  box-sizing: border-box;
  color: var(--media-tooltip-color, hsl(0, 0%, 80%));
  display: block;
  font-family: var(--media-font-family, sans-serif);
  font-size: var(--media-tooltip-font-size, 13px);
  font-weight: var(--media-tooltip-font-weight, 500);
  left: 50%;
  bottom: 80%;
  margin-left: unset;
  margin-bottom: var(--media-tooltip-y-offset, 12px);
  opacity: 0;
  padding: var(--media-tooltip-padding, 2px 8px);
  pointer-events: none;
  position: absolute;
  transform-origin: 50% 100%;
  transform: translate(-50%, 12px) scale(0.8);
  transition: transform 0.2s ease-out 0.1s, opacity 0.2s ease-out 0.1s;
  white-space: nowrap;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Left
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~k
 */

:where(media-tooltip[position~='left']) {
  left: 0;
  transform: translateY(12px) scale(0.8);
  transform-origin: 0 100%;
  margin-left: var(--media-tooltip-x-offset, 0);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Right
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~k
 */

:where(media-tooltip[position~='right']) {
  left: auto;
  right: 0;
  margin-left: unset;
  margin-right: var(--media-tooltip-x-offset, 0);
  transform: translateY(12px) scale(0.8);
  transform-origin: 100% 100%;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Bottom
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~k
 */

:where(media-tooltip[position~='bottom']) {
  top: 80%;
  bottom: unset;
  margin-top: var(--media-tooltip-y-offset, 12px);
  margin-bottom: unset;
  transform: translate(-50%, -12px) scale(1);
}

:where(media-tooltip[position='bottom left'], media-tooltip[position='bottom right']) {
  transform: translateY(-12px) scale(0.8);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Display
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~k
 */

:where(media-menu [data-media-menu-button][role='button'][data-pressed] media-tooltip) {
  opacity: 0;
  display: none;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Hover / Focus
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~k
 */

@media (pointer: fine) {
  :where(media-player [data-media-button][data-hocus] media-tooltip) {
    opacity: 1;
    transform: translate(-50%) scale(1);
    transition: transform 0.2s ease-in 0.1s, opacity 0.2s ease-in 0.1s;
  }

  :where(media-player [data-media-button][data-hocus] media-tooltip[position~='left']) {
    transform: translate(0) scale(1);
  }

  :where(media-player [data-media-button][data-hocus] media-tooltip[position~='right']) {
    transform: translate(0) scale(1);
  }
}
